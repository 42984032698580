@import url(https://fonts.googleapis.com/css2?family=Mitr&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.customDatePicker {
  max-width: 10em
}

.react-calendar {
 background-color: #fff;
 color: #222;
 border: #fff;
 width: 100%
}

.react-calendar__tile--active {
    background: #fff
}
.react-calendar__tile--active:enabled:hover, .react-calendar__tile--active:enabled:focus {
    background: #04a1d9;
}

.react-calendar__navigation button {
  color: #005792;
  font-weight: bold;
  font-family: Lato
}

.react-calendar__navigation__label {
  color: #005792
}
.react-calendar__navigation__arrow {
  font-size: 48px;
  padding-left: 5%;
  padding-right: 5%;
}

.react-calendar__tile--now {
  background : #87cefaad
}
.react-calendar__tile--now:enabled:hover, .react-calendar__tile--now:enabled:focus {
    background: #04a1d9;
}

.react-calendar__month-view__days__day {
  color: black;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: darkgrey;
}
.react-calendar__month-view__weekdays__weekday {
  color: darkgrey;
}

@media only screen
and (min-device-width: 600px) {
.customcalendar {
  width: 100%;
  font-size: 16px;
  font-family: 'Lato'
}
.react-calendar {
 border-radius: 8px;
 box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3);
 margin-top: 2%
}
abbr {
  font-size: 18px;
  font-weight: bold;
}
abbr[title] {
  font-size: 18px;
  text-decoration: underline
}

.react-calendar__navigation  {
  height: 75px;
  font-size: 30px;
}

.react-calendar__tile {
  display: flex;
  flex-direction: column;
  align-items: center;
  height:250px;
  border-radius:10px;
}

}

@media only screen
and (max-device-width: 600px)
 {
   .react-calendar__tile {
     display: flex;
     flex-direction: column;
     align-items: center;
     border-radius:10px;
   }
   .react-calendar__navigation__arrow {
     font-size: 32px
   }

   abbr[title] {
     text-decoration: underline
   }
}

a.nav_link {
  color: black
}

.stripe-input {
  border-radius: 6px;
  margin-bottom: 6px;
  padding: 12px;
  border: 1px solid rgba(50, 50, 93, 0.1);
  max-height: 44px;
  font-size: 16px;
  width: 100%;
  background: white;
  box-sizing: border-box;
}

.result-message {
  line-height: 22px;
  font-size: 16px;
}

.result-message a {
  color: rgb(89, 111, 214);
  font-weight: 600;
  text-decoration: none;
}

.hidden {
  display: none;
}

.card-error {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  margin-top: 12px;
  text-align: center;
}

.card-element {
  border-radius: 4px 4px 0 0;
  padding: 12px;
  border: 1px solid rgba(50, 50, 93, 0.1);
  max-height: 44px;
  width: 100%;
  background: white;
  box-sizing: border-box;
}

#payment-request-button {
  margin-bottom: 32px;
}

/* Buttons and links */
.stripe-button {
  background: #5469d4;
  font-family: Arial, sans-serif;
  color: #ffffff;
  border-radius: 0 0 4px 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
}

.stripe-button:hover {
  -webkit-filter: contrast(115%);
          filter: contrast(115%);
}

.stripe-button:disabled {
  opacity: 0.5;
  cursor: default;
}

/* spinner/processing state, errors */
.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}

.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}

.spinner:before,
.spinner:after {
  position: absolute;
  content: "";
}

.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}

.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

@-webkit-keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 600px) {
  form {
    width: 80vw;
  }
}

