.react-calendar {
 background-color: #fff;
 color: #222;
 border: #fff;
 width: 100%
}

.react-calendar__tile--active {
    background: #fff
}
.react-calendar__tile--active:enabled:hover, .react-calendar__tile--active:enabled:focus {
    background: #04a1d9;
}

.react-calendar__navigation button {
  color: #005792;
  font-weight: bold;
  font-family: Lato
}

.react-calendar__navigation__label {
  color: #005792
}
.react-calendar__navigation__arrow {
  font-size: 48px;
  padding-left: 5%;
  padding-right: 5%;
}

.react-calendar__tile--now {
  background : #87cefaad
}
.react-calendar__tile--now:enabled:hover, .react-calendar__tile--now:enabled:focus {
    background: #04a1d9;
}

.react-calendar__month-view__days__day {
  color: black;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: darkgrey;
}
.react-calendar__month-view__weekdays__weekday {
  color: darkgrey;
}

@media only screen
and (min-device-width: 600px) {
.customcalendar {
  width: 100%;
  font-size: 16px;
  font-family: 'Lato'
}
.react-calendar {
 border-radius: 8px;
 box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3);
 margin-top: 2%
}
abbr {
  font-size: 18px;
  font-weight: bold;
}
abbr[title] {
  font-size: 18px;
  text-decoration: underline
}

.react-calendar__navigation  {
  height: 75px;
  font-size: 30px;
}

.react-calendar__tile {
  display: flex;
  flex-direction: column;
  align-items: center;
  height:250px;
  border-radius:10px;
}

}

@media only screen
and (max-device-width: 600px)
 {
   .react-calendar__tile {
     display: flex;
     flex-direction: column;
     align-items: center;
     border-radius:10px;
   }
   .react-calendar__navigation__arrow {
     font-size: 32px
   }

   abbr[title] {
     text-decoration: underline
   }
}
